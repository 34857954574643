import React, { FC } from 'react';
import {
	ChipCloseButton,
	ChipItem,
	ChipList,
	ChipListContainer,
} from '../../ChipListContainer/ChipListContainer.styles';
import CloseIcon from '../../../../assets/icon/times-dark.svg';
import Select, { components, MenuListComponentProps, Props, IndicatorProps } from 'react-select';
import { ClearIndicatorContainer, MenuControlContainer, MenuControlLabel } from './MultiSelect.styles';
import Checkbox from '../../primaryCheckbox/Checkbox';
import { OptionTypeBase } from 'react-select/src/types';
import { generalSelectTheme, multiSelectStyles } from '../../../../utils/reactselectstyles';
import CheckboxOption from '../CheckboxOption/CheckboxOption';

interface MultiSelectExtraProps<T> {
	value: T[];
	showChips?: boolean;
	selectCheckboxLabel?: string;
	deselectCheckboxLabel?: string;
	isGroup?: boolean;
	disableSelectAll?: boolean;
}

export type MultiSelectProps<T> = Omit<Props<T>, 'isMulti' | 'value'> & MultiSelectExtraProps<T>;

const MultiSelect = (props: MultiSelectProps<OptionTypeBase>) => {
	const { components, ...rest } = props;
	return (
		<>
			<Select
				styles={multiSelectStyles(props.hasError)}
				theme={generalSelectTheme}
				isMulti
				closeMenuOnSelect={false}
				isClearable
				controlShouldRenderValue={!props.showChips}
				hideSelectedOptions={false}
				blurInputOnSelect={false}
				isGroup={props.isGroup}
				components={{
					...components,
					IndicatorSeparator: () => null,
					Option: CheckboxOption,
					MenuList: CustomMenuList,
					ClearIndicator: CustomClearIndicator,
				}}
				{...rest}
			/>

			{props.showChips ? (
				<ChipListContainer hasError={props.hasError}>
					<ChipList disabled={props.isDisabled}>
						{props.value?.map((option: OptionTypeBase) => (
							<ChipItem key={option?.value}>
								<div className="label">{option?.label}</div>
								<ChipCloseButton
									type="button"
									onClick={() => {
										if (props.isDisabled) return;
										if (props.isGroup) {
											return props.onChange([option], { action: 'deselect-option' });
										}
										const newValue = props.value?.filter((val) => option?.value !== val?.value);
										props.onChange(newValue);
									}}
								>
									<img src={CloseIcon} alt="close-button" />
								</ChipCloseButton>
							</ChipItem>
						))}
					</ChipList>
				</ChipListContainer>
			) : null}
		</>
	);
};

const deepEqual = (obj1: any, obj2: any) => {
	if (obj1 === obj2) return true;
	if (obj1 === null || obj2 === null) return false;
	if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

	let keys1 = Object.keys(obj1);
	let keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) return false;

	for (let key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
	}

	return true;
};

const areAllObjectsInArray = (arr1: any, arr2: any) => {
	return arr1.every((obj1: any) => arr2.some((obj2: any) => deepEqual(obj1, obj2)));
};

const CustomMenuList: FC<MenuListComponentProps<OptionTypeBase>> = (props: MenuListComponentProps<OptionTypeBase>) => {
	const { selectProps, getValue, setValue, clearValue } = props;
	const { selectCheckboxLabel, deselectCheckboxLabel, isGroup, disableSelectAll, inputValue } = selectProps;
	const options = isGroup
		? props.options.reduce((prev: OptionTypeBase[], opt) => [...prev, ...opt.options], [])
		: props.options;
	const enabledOptions = options.filter((option) => !option.isDisabled);
	const optionsLength = enabledOptions.length;
	const isAllOptionsSelected = optionsLength === getValue()?.length;
	const lowerCaseOptions: OptionTypeBase[] = enabledOptions.map(({ label, value }) => ({
		label: label === null ? '' : label.toLowerCase(),
		value,
	}));
	const prevValues = (getValue() as OptionTypeBase[]).map(({ label, value }) => ({
		label: label === null ? '' : label.toLowerCase(),
		value,
	}));
	const searchedOptionsGlobal = lowerCaseOptions.filter(({ label, value }) =>
		label.includes(inputValue?.toLowerCase()),
	);
	const isSearchedOptionsSelected = areAllObjectsInArray(searchedOptionsGlobal, prevValues);

	const handleControlClicked = () => {
		if (isGroup) {
			return setValue(enabledOptions, isAllOptionsSelected ? 'deselect-option' : 'select-option');
		}

		if (isAllOptionsSelected || isSearchedOptionsSelected) {
			return clearValue();
		}

		if (inputValue) {
			let searchedOptions = lowerCaseOptions.filter(({ label, value }) => label.includes(inputValue.toLowerCase()));
			if (getValue()?.length > 0) {
				searchedOptions = [...prevValues, ...searchedOptions];
			}

			// function removes duplicate options if the user clicks multiple times on select all since options are being appended
			const uniqueSearchedOptions = searchedOptions.filter(
				(option, index, self) => index === self.findIndex((t) => t.label === option.label && t.value === option.value),
			);
			return setValue(uniqueSearchedOptions, 'select-option');
		}

		setValue(enabledOptions, 'select-option');
	};

	if (optionsLength <= 1 || disableSelectAll) {
		return <components.MenuList {...props} />;
	}

	return (
		<>
			<components.MenuList {...props} />
			<MenuControlContainer
				onClick={(event) => {
					handleControlClicked();
					event.stopPropagation();
					event.preventDefault();
				}}
				onTouchStart={(event) => {
					event.preventDefault();
					handleControlClicked();
				}}
				onTouchEnd={(event) => {
					event.preventDefault();
				}}
			>
				<Checkbox checked={isAllOptionsSelected || isSearchedOptionsSelected} shouldPropagate />
				<MenuControlLabel>
					{isAllOptionsSelected || isSearchedOptionsSelected ? deselectCheckboxLabel : selectCheckboxLabel}
				</MenuControlLabel>
			</MenuControlContainer>
		</>
	);
};

const CustomClearIndicator = (props: IndicatorProps<OptionTypeBase>) => {
	if (!props.selectProps.isClearable) return null;

	return (
		<ClearIndicatorContainer>
			<components.ClearIndicator {...props} />
		</ClearIndicatorContainer>
	);
};

export default MultiSelect;

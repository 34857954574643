import { createReducer, ActionType } from 'typesafe-actions';
import * as types from './featureAccess.actionTypes';
import * as actions from './featureAccess.action';
import { FeatureAccessState } from './type';

export const initialState: FeatureAccessState = {
	features: {
		PHOTO_ATTACHMENT_ANNOTATION: false,
		AUTO_GENERATED_REPORTS: false,
		ADVANCED_ANALYTICS: false,
		CUSTOMIZABLE_QUESTIONNAIRE: false,
		INDUSTRY_STANDARD_QUESTIONNAIRES: false,
		STANDARD_VISUAL_ANALYTICS: false,
		MESSAGE_BROADCAST: false,
		WEIGHTED_SCORING: false,
		ADHOC_REPORTING: false,
		GEO_TAGGING: false,
		MULTI_LANGUAGE_SUPPORT: false,
		ISSUE_TRACKER: false,
		ISSUE_TRACKER_V2: false,
		CUSTOMIZED_LOCALIZED_MARKETING: false,
		SALES_TARGET_TRACKER: false,
		TEAM_INSPECTION_CAPABILITIES: false,
		INSIGHTS_NOTIFICATIONS: false,
		DIGITAL_SELFIE_SIGNOFFS: false,
		INVENTORY_MANAGEMENT: false,
		DATA_EXPORT: false,
		ISSUE_TRACKER_ESCALATION: false,
		JOURNEY_PLAN: false,
		COMPETITOR_ANALYSIS: false,
		CUSTOMER_FEEDBACK: false,
		DASHBOARD_V2: false,
		OFFLINE_AUDIT: false,
		FEDERATED_AUTHENTICATION: false,
		IRF_SAT: false,
		LMS: false,
		LMS_QUIZ: false,
		ATTACHMENT_GALLERY: false,
		STATEMENT_OF_REPORTS: false,
		NIMBLY_LITE: false,
		WHATSAPP_NOTIFICATION: false,
		HEALTH_DASHBOARD: false,
		AUDITOR_NON_OPERATIONAL_DAYS: false,
		OPENAI_RECOMMENDATION: false,
		REPORTSHUB_V2: false,
		ISSUE_TRACKER_ATTACHMENT_PREVIEW: false,
		FILE_REPOSITORY: false,
		DASHBOARD_REVAMP: false,
		DRAFT_CHECKLIST: false,
		BULK_OPERATIONS: false,
		ISSUE_TRACKER_MOBILE_APP: false,
		OBJECT_DETECTION: false,
	},
	packageType: 'starter',
	isLoading: false,
	isLoaded: false,
	error: null,
	modalVisible: false,
	currentOrg: '',
};

export type FeatureAccessAction = ActionType<typeof actions>;
const featureAccessReducer = createReducer<FeatureAccessState, FeatureAccessAction>(initialState)
	.handleAction(types.SET_PACKAGE_TYPE as any, (state, action) => ({
		...state,
		packageType: action.payload.packageType,
	}))
	.handleAction(types.FETCH_FEATURE_ACCESS_REQUEST as any, (state, action) => ({
		...state,
		isLoading: true,
	}))
	.handleAction(types.FETCH_FEATURE_ACCESS_SUCCESS as any, (state, action) => {
		return {
			...state,
			isLoading: false,
			isLoaded: true,
			features: { ...action.payload.data },
			packageType: action.payload.packageType,
		};
	})
	.handleAction(types.FETCH_FEATURE_ACCESS_FAILURE as any, (state, action) => ({
		...state,
		isLoading: false,
		error: action.payload.error,
	}))
	.handleAction(types.SET_ORG as any, (state, action) => ({
		...state,
		currentOrg: action.payload.org,
	}))
	.handleAction(types.SHOW_FEATURE_MODAL as any, (state, action) => ({
		...state,
		modalVisible: true,
	}))
	.handleAction(types.DISMISS_FEATURE_MODAL as any, (state, action) => ({
		...state,
		modalVisible: false,
	}));

export { featureAccessReducer };

import { store } from 'store/configStore';
import { apiURL, apiURLAlt } from 'config/baseURL';
import API from 'helpers/api';
import {
	TotalSitesInfo,
	TotalUsersInfo,
	TotalLoginDevicesInfo,
	AvgLoginInfo,
	UsersCvUInfo,
	SitesCvUInfo,
	AvgVTotalReportsInfo,
	MAUInfo,
	StorageInfo,
	AdminDashboardFilters,
} from 'reducers/adminDashboard/adminDashboardStore';
import { filterObjectWithEmptyValues } from 'pages/dashboardRevamp/utils/filterObjectWithEmptyValues';
import { cloneDeep } from 'lodash';
import { downloadURL } from 'utils/download';
import moment from 'moment';
import { queryStringify } from 'utils/router';

export const buildQueryParams = (addlQueryParams?: object) => {
	const { filters, organizationId } = store.getState().adminDashboard;
	if (addlQueryParams) {
		const query = queryStringify({ ...filters, organizationID: organizationId, ...addlQueryParams }, undefined, true);
		return query;
	}
	const query = queryStringify(
		{
			...filters,
			organizationID: organizationId,
		},
		undefined,
		true,
	);
	return query;
};

export const requestOptions = async (method: string, data?: object) => {
	const token = await API.getFirebaseToken();
	const headers = {
		Authorization: token,
		'Content-Type': 'application/json',
	};

	if (method.toUpperCase() === 'GET') {
		return {
			method: method,
			headers: headers,
		};
	}

	return {
		method: method,
		headers: headers,
		body: JSON.stringify(data),
	};
};

export const fetchData = async <T>(endpoint: string, requestMethod: string, URL?: string): Promise<T | undefined> => {
	try {
		const { filters, organizationId } = store.getState().adminDashboard;
		let updatedFilters: AdminDashboardFilters = { ...filters };
		if (moment(filters.endDate).diff(moment(filters.startDate), 'days') > 93) {
			updatedFilters.startDate = moment(filters.endDate).subtract(93, 'days').format('YYYY-MM-DD');
		}
		const queryParams = {
			...updatedFilters,
			organizationID: organizationId,
		};
		const url = `${URL ? URL : apiURL}/${endpoint}`;
		const options = await requestOptions(requestMethod, queryParams);
		const response = await fetch(url, options);
		const { data, message } = await response.json();

		if (response.status === 200) {
			return data as T;
		} else {
			return undefined;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchTotalUsers = async (): Promise<TotalUsersInfo | undefined> => {
	return fetchData<TotalUsersInfo>('users/user-activity', 'POST');
};

export const fetchTotalSites = async (): Promise<TotalSitesInfo | undefined> => {
	return fetchData<TotalSitesInfo>('sites/metering/active-sites', 'POST');
};

export const fetchTotalLoginDevices = async (): Promise<TotalLoginDevicesInfo | undefined> => {
	return fetchData<TotalLoginDevicesInfo>('users/login-platform', 'POST');
};

export const fetchAvgLoginInfo = async (): Promise<AvgLoginInfo | undefined> => {
	return fetchData<AvgLoginInfo>('activities/uniqueLogins/average', 'POST', apiURLAlt);
};

export const fetchUsersCvU = async (): Promise<UsersCvUInfo[] | string | undefined> => {
	return fetchData<UsersCvUInfo[] | string>('users/user-stats', 'POST');
};

export const fetchSitesCvU = async (): Promise<SitesCvUInfo[] | string | undefined> => {
	return fetchData<SitesCvUInfo[] | string>('sites/metering/site-stats', 'POST');
};

export const fetchAvgVTotalReports = async (): Promise<AvgVTotalReportsInfo[] | string | undefined> => {
	return fetchData<AvgVTotalReportsInfo[] | string>('reports/metering/reports', 'POST', apiURLAlt);
};

export const fetchMAU = async (): Promise<MAUInfo[] | string | undefined> => {
	return fetchData<MAUInfo[] | string>('activities/users/activeusers', 'POST', apiURLAlt);
};

export const downloadOrgReport = async (): Promise<boolean | undefined> => {
	try {
		const { organizationId } = store.getState().adminDashboard;
		const queryParams = buildQueryParams({ source: 'metering' });
		const url = `${apiURL}/nps/metrics?${queryParams}`;
		const options = await requestOptions('GET', queryParams);
		const response = await fetch(url, options);

		if (response && response.status === 200) {
			const responseData = await response.json();
			downloadURL(responseData?.data, `${organizationId}_${moment().unix()}.xlsx`);
			return true;
		} else {
			return false;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchStorageInfo = async (): Promise<StorageInfo | undefined> => {
	try {
		const { organizationId } = store.getState().adminDashboard;
		const url = `${apiURL}/file-repo/tracker/${organizationId}`;
		const token = await API.getFirebaseToken();
		const options = {
			method: 'GET',
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
		};
		const response = await fetch(url, options);
		const { data, message } = await response.json();

		if (response.status === 200 && data !== null) {
			return { totalAllowedStorageGB: data.totalAllowedStorageGB, totalUsedStorageGB: data.totalUsedStorageGB };
		} else if (response.status === 200 && data === null) {
			return { totalAllowedStorageGB: 0, totalUsedStorageGB: 0 };
		} else {
			return undefined;
		}
	} catch (error) {}
};

export const fetchInvoiceReport = async (startDate: string, endDate: string): Promise<string | undefined> => {
	try {
		const url = `${apiURLAlt}/nps/finance-metrics?startDate=${startDate}&endDate=${endDate}`;
		const token = await API.getFirebaseToken();
		const options = {
			method: 'GET',
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
		};
		const response = await fetch(url, options);
		const { data, message } = await response.json();

		if (response.status === 200) {
			downloadURL(data, `nimbly-invoice-report.xlsx`);
			return 'SUCCESS';
		} else {
			return 'FAILED';
		}
	} catch (error) {}
};

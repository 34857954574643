import { AttachmentGalleryResponse, FilterAttachmentsResult, IssueType } from '@nimbly-technologies/nimbly-common';
import { AttachmentDetails } from 'components/gallery/component/AttachmentThumbnailCard';
import { AttachmentResult } from 'services/gallery/gallery.service';

export enum GalleryTabEnum {
	LIBRARY = 'library',
	ALBUM = 'album',
	DETAIL = 'detail',
	SEARCH = 'search',
}

export enum AttachmentSourceType {
	ISSUE = 'issue',
	REPORT = 'report',
}

export enum AttachmentType {
	IMAGE = 'image',
	VIDEO = 'video',
}

export enum GalleryGroupByType {
	ALL = 'all',

	DAYS = 'days',
	MONTHS = 'months',
	YEARS = 'years',

	SITES = 'sites',
	QUESTIONS = 'questions',
	CATEGORIES = 'categories',
	ISSUE_ID = 'issueID',
}

export interface AttachmentGallery {
	_id: string;
	issueID?: string;
	reportID?: string;
	organizationID: string;
	siteID: string;
	deptID: string;
	supervisorID: string;
	scheduledDate: string;
	questionnaireID: string;
	fileSize: number;
	category: string;
	question: string;
	answer: string;
	attachmentType: string;
	origin: string;
	attachmentPath: string;
	screenshotPath?: string;
	createdAt: string;
	updatedAt: string;
}

export enum GalleryFilterEnum {
	SOURCE = 'source',
	TYPE = 'type',
	QUESTIONNAIRE = 'qnrs',
	AUDITORS = 'auditors',
	SITE_ID = 'siteID',
	DEPT_ID = 'deptIDs',

	START_DATE = 'startDate',
	END_DATE = 'endDate',
}

export interface GalleryFilters {
	source: IssueType[];
	type: AttachmentType[];
	qnrs: string[];
	startDate: string | null; // YYYY-MM-DD
	endDate: string | null; // YYYY-MM-DD
	auditors: string[];
	siteID: string[];
	deptIDs: string[];
}

export const galleryFilterArray = [
	GalleryFilterEnum.SOURCE,
	GalleryFilterEnum.TYPE,
	GalleryFilterEnum.QUESTIONNAIRE,
	GalleryFilterEnum.AUDITORS,
	GalleryFilterEnum.SITE_ID,
	GalleryFilterEnum.DEPT_ID,
];

export type GalleryAttachments = AttachmentGalleryResponse[];
export type GallerySections = Record<string, AttachmentGalleryResponse[]>;

export interface SearchAttachmentResult {
	questionMatches: AttachmentGalleryResponse[];
	questionMatchCount: number;
	categoryMatches: AttachmentGalleryResponse[];
	categoryMatchCount: number;
	issueMatchCount: number;
	issueMatches: AttachmentGalleryResponse[];
}

export interface GalleryProgressModalConfig {
	isVisible: boolean;
	count: number;
	total: number;
}

export interface GalleryState {
	error: string;
	isLoading: boolean;
	isLoadingNextPage: boolean;
	isLoadingSearch: boolean;
	activeTab: GalleryTabEnum;
	gridSize: number;

	page: number;
	limit: number;
	totalPages: number;
	totalDocs: number;
	canLoadMore: boolean;

	attachments: GalleryAttachments;
	sections: GallerySections;
	selectedAttachment: AttachmentGalleryResponse | null;
	selectedAttachmentIds: Set<string>;
	totalSelectedAttachments: number;
	totalSelectedAttachmentsSize: number;
	selectedAlbum: AttachmentDetails | undefined;
	selectedAlbumIds: Set<string>;
	selectedDates: Set<string>;

	searchQuery: string;
	searchData: SearchAttachmentResult;
	filters: GalleryFilters;
	groupBy: GalleryGroupByType;
	groupByCount?: [
		{
			question?: string;
			category?: string;
			noOfAttachments: number;
		},
	];

	isFilterOpen: boolean;
	isSelectMode: boolean;

	isDownloadErrorModalVisible: boolean;
	isDownloadInitializationModalVisible: boolean;
	downloadProgressModalConfig: GalleryProgressModalConfig;
}

export type FetchAttachmentsSuccessPayload = Omit<FilterAttachmentsResult, 'results'> & {
	attachments: AttachmentGalleryResponse[];
	sections: Record<string, AttachmentGalleryResponse[]>;
};

import {
	CreateIssueMessageRequest,
	CreateIssueTrackerFilterRequest,
	IssueTrackerFilter,
	UpdateIssueRequest,
	UpdateIssueTrackerFilterRequest,
} from '@nimbly-technologies/nimbly-common';
import {
	NIMBLY_API_ISSUES_BULK_EDIT,
	NIMBLY_API_ISSUES_BULK_MESSAGE,
	NIMBLY_API_ISSUES_CUSTOM_FILTER,
} from 'config/baseURL';
import API from 'helpers/api';

export type BulkEditIssuesData = Pick<
	UpdateIssueRequest,
	'status' | 'priorityV2' | 'severity' | 'dueDate' | 'assignedDepartments' | 'assignedTo'
>;

export type BulkEditIssuesPayloadData = Partial<BulkEditIssuesData>;

export type bulkIssueMessageType = Pick<
	CreateIssueMessageRequest,
	'createdAt' | 'createdBy' | 'createdByName' | 'message' | 'messageType'
>;

export const bulkEditIssues = async (issueIDs: string[], changedValues: BulkEditIssuesPayloadData) => {
	const url = NIMBLY_API_ISSUES_BULK_EDIT;
	const token = await API.getFirebaseToken();
	const payload = {
		issueIDs,
		data: {
			...changedValues,
			triggerNotification: true,
		},
	};
	const response = await API.put(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const bulkSubmitIssueMesage = async (issueIDs: string[], data: bulkIssueMessageType) => {
	const url = NIMBLY_API_ISSUES_BULK_MESSAGE;
	const token = await API.getFirebaseToken();
	const payload = {
		issueIDs,
		data: {
			...data,
			triggerNotification: true,
		},
	};
	const response = await API.post(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const getIssueCustomFilter = async (): Promise<IssueTrackerFilter[]> => {
	const url = NIMBLY_API_ISSUES_CUSTOM_FILTER;
	const token = await API.getFirebaseToken();

	const response = await API.get(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const saveIssueCustomFilter = async (payload: CreateIssueTrackerFilterRequest) => {
	const url = NIMBLY_API_ISSUES_CUSTOM_FILTER;
	const token = await API.getFirebaseToken();

	const response = await API.post(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const updateIssueCustomFilter = async (filterId: string, payload: UpdateIssueTrackerFilterRequest) => {
	const url = `${NIMBLY_API_ISSUES_CUSTOM_FILTER}/${filterId}`;
	const token = await API.getFirebaseToken();

	const response = await API.put(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const deleteIssueCustomFilter = async (filterId: string) => {
	const url = `${NIMBLY_API_ISSUES_CUSTOM_FILTER}/${filterId}`;
	const token = await API.getFirebaseToken();

	const response = await API.delete(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

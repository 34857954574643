export enum ReportType {
	ALL = 'all',
	SCHEDULE = 'schedule',
	INVENTORY = 'inventory',
	ISSUE = 'issue',
}

export enum ScheduleStatus {
	COMPLETED = 'completed',
	MISSED = 'missed',
}

export enum ReportFormat {
	PDF = 'pdf',
	EXCEL = 'xlsx',
}

export enum ReportTypeTab {}

export interface ReportsData {
	id: string;
	departments: Array<string>;
	departmentGroups: Array<string>;
	sites: Array<string>;
	auditors: Array<string>;
	questionnaires: Array<string>;
	dJobID: string;
	endDate: string;
	startDate: string;
	reportsCount: number;
	reportFormat: string;
	isConsolidated: boolean;
	type: string;
	organizationID: string;
	requestorID: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	__v: number;

	retryAt?: string;
	downloadURL: string;
	downloadURLPDF: string;
	progress: number;
	requestedTimestamp: string;
	issuesCount: number;
	isWithAttachments?: boolean;
	failureReason?: string;
}

export interface ReportsTableData {
	docs: ReportsData[];
	totalDocs: number;
	limit: number;
	page: number;
	totalPages: number;
}

export interface ReportCountDetails {
	reportsCount: number;
	recordsCount: number;
	attachmentsCount: number;
	isExist: boolean;
}

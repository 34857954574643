import {
	BaseOptionObject,
	BaseSelectOptionType,
	mapOptionsDataToObject,
} from 'components/global/Select/utils/optionsMapper';
import { useUserDataOptions } from 'components/permissions/utils/useUserDataOptions';
import { useUserDataVisibilityOptions } from 'components/permissions/utils/useUserDataVisibilityOptions';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampaignList } from 'reducers/customerFeedback/customerFeedback.action';
import { fetchDepartments, fetchDepartmentsDataVisibility } from 'reducers/departments/departments.action';
import { getQuestionnairesByPropsAsync } from 'reducers/questionnaire/questionnaire.action';
import { siteCompactAction } from 'reducers/site/siteCompact/siteCompact.action';
import { RootState } from 'store/rootReducers';
import {
	IssueFlagType,
	IssuePriorityType,
	IssueSecondaryStatusType,
	IssueStatusType,
	IssueType,
} from '../IssueTracker.types';

const useIssueDataOptions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const questionnaires = useSelector((state: RootState) => state.questionnaireByProps.data);
	const campaigns = useSelector((state: RootState) => state.customerFeedbackList.campaignList);
	const departments = useSelector((state: RootState) => state.departments.departments) || {};
	const sites = useSelector((state: RootState) => state.siteCompact.data);
	const departmentsDataVisibility =
		useSelector((state: RootState) => state.departments.departmentsDataVisibility) || {};

	const isSitesLoading = useSelector((state: RootState) => state.siteCompact.isLoading);
	useEffect(() => {
		if (!sites.length && !isSitesLoading) {
			dispatch(siteCompactAction.request({}));
		}
	}, [sites.length, isSitesLoading]);

	const isQuestionnairesLoading = useSelector((state: RootState) => state.questionnaireByProps.isLoading);
	useEffect(() => {
		if (!questionnaires.length && !isQuestionnairesLoading) {
			dispatch(getQuestionnairesByPropsAsync.request());
		}
	}, [questionnaires.length, isQuestionnairesLoading]);

	const isCampaignsLoading = useSelector((state: RootState) => state.customerFeedbackList.isLoading);
	useEffect(() => {
		if (!campaigns.length && !isCampaignsLoading) {
			dispatch(fetchCampaignList.request());
		}
	}, [campaigns.length, isCampaignsLoading]);

	const isDepartmentsLoading = useSelector((state: RootState) => state.departments.isLoadingDepartments);
	useEffect(() => {
		if (!Object.keys(departments).length && !isDepartmentsLoading) {
			dispatch(fetchDepartments.request());
		}
	}, [departments, isDepartmentsLoading]);

	useEffect(() => {
		dispatch(fetchDepartmentsDataVisibility.request());
	}, []);

	const questionnaireMap = useMemo<BaseOptionObject>(
		() =>
			mapOptionsDataToObject({
				data: questionnaires,
				key: 'questionnaireIndexID',
				value: 'questionnaireIndexID',
				label: 'title',
			}),
		[questionnaires],
	);
	const questionnaireOptions: BaseSelectOptionType[] = useMemo(() => Object.values(questionnaireMap), [
		questionnaireMap,
	]);

	const surveyMap = useMemo<BaseOptionObject>(
		() =>
			mapOptionsDataToObject({
				data: campaigns,
				key: 'questionnaireID',
				value: 'questionnaireID',
				label: 'title',
			}),
		[campaigns],
	);
	const surveyOptions: BaseSelectOptionType[] = useMemo(() => Object.values(surveyMap), [surveyMap]);

	const { userMap, userOptions } = useUserDataOptions();
	const { userDataVisibilityMap, userDataVisibilityOptions } = useUserDataVisibilityOptions();

	const departmentMap = useMemo<BaseOptionObject>(
		() =>
			mapOptionsDataToObject({
				data: Object.values(departments),
				key: 'departmentID',
				value: 'departmentID',
				label: 'name',
			}),
		[departments],
	);
	const departmentOptions: BaseSelectOptionType[] = useMemo(() => Object.values(departmentMap), [departmentMap]);

	const siteMap = useMemo<BaseOptionObject>(
		() => mapOptionsDataToObject({ data: sites, key: 'siteID', value: 'siteID', label: 'name' }),
		[sites],
	);
	const siteOptions: BaseSelectOptionType[] = useMemo(() => Object.values(siteMap), [siteMap]);

	const departmentDataVisibilityMap = useMemo<BaseOptionObject>(
		() =>
			mapOptionsDataToObject({
				data: Object.values(departmentsDataVisibility),
				key: 'departmentID',
				value: 'departmentID',
				label: 'name',
			}),
		[departmentsDataVisibility],
	);
	const departmentDataVisibilityOptions: BaseSelectOptionType[] = useMemo(
		() => Object.values(departmentDataVisibilityMap),
		[departmentDataVisibilityMap],
	);

	const issueTypeMap: { [key: string]: BaseSelectOptionType } = {
		[IssueType.MANUAL_ADDITION]: {
			value: IssueType.MANUAL_ADDITION,
			label: t('label.issuePage.type.manual-addition'),
		},
		[IssueType.REPORT]: {
			value: IssueType.REPORT,
			label: t('label.issuePage.type.report'),
		},
		[IssueType.CUSTOMER_FEEDBACK]: {
			value: IssueType.CUSTOMER_FEEDBACK,
			label: t('label.issuePage.type.customer-feedback'),
		},
	};

	const flagMap: BaseOptionObject = {
		[IssueFlagType.RED]: {
			value: IssueFlagType.RED,
			label: t('label.dashboard.red'),
		},
		[IssueFlagType.YELLOW]: {
			value: IssueFlagType.YELLOW,
			label: t('label.dashboard.yellow'),
		},
	};

	const priorityMap: BaseOptionObject = {
		[IssuePriorityType.LOW]: {
			value: IssuePriorityType.LOW,
			label: t('label.issuePage.low'),
		},
		[IssuePriorityType.MEDIUM]: {
			value: IssuePriorityType.MEDIUM,
			label: t('label.issuePage.medium'),
		},
		[IssuePriorityType.HIGH]: {
			value: IssuePriorityType.HIGH,
			label: t('label.issuePage.high'),
		},
	};

	const statusMap: BaseOptionObject<IssueStatusType> = {
		[IssueStatusType.OPEN]: {
			value: IssueStatusType.OPEN,
			label: t('label.issuePage.status.open'),
			sublabel: t('label.issuePage.status.openSublabel'),
		},
		[IssueStatusType.IN_PROGRESS]: {
			value: IssueStatusType.IN_PROGRESS,
			label: t('label.issuePage.status.in-progress'),
			sublabel: t('label.issuePage.status.inProgressSublabel'),
		},
		[IssueStatusType.BLOCKED]: {
			value: IssueStatusType.BLOCKED,
			label: t('label.issuePage.status.blocked'),
			sublabel: t('label.issuePage.status.blockedSublabel'),
		},
		[IssueStatusType.IN_REVIEW]: {
			value: IssueStatusType.IN_REVIEW,
			label: t('label.issuePage.status.in-review'),
			sublabel: t('label.issuePage.status.inReviewSublabel'),
		},
		[IssueStatusType.RESOLVED]: {
			value: IssueStatusType.RESOLVED,
			label: t('label.issuePage.status.resolved'),
			sublabel: t('label.issuePage.status.resolvedSublabel'),
		},
	};

	const resolvedStatusMap: BaseOptionObject = {
		[IssueSecondaryStatusType.ON_TIME]: {
			label: t('label.issuePage.status.resolvedOnTime'),
			value: IssueStatusType.RESOLVED,
		},
		[IssueSecondaryStatusType.BEHIND_TIME]: {
			label: t('label.issuePage.status.resolvedBehindTime'),
			value: IssueStatusType.RESOLVED,
		},
	};

	const secondaryStatusMap: BaseOptionObject = {
		[IssueSecondaryStatusType.OVERDUE]: {
			value: IssueSecondaryStatusType.OVERDUE,
			label: t('label.issuePage.secondaryStatus.overdue'),
		},
		[IssueSecondaryStatusType.BEHIND_TIME]: {
			value: IssueSecondaryStatusType.BEHIND_TIME,
			label: t('label.issuePage.secondaryStatus.behind-time'),
		},
		[IssueSecondaryStatusType.ON_TIME]: {
			value: IssueSecondaryStatusType.ON_TIME,
			label: t('label.issuePage.secondaryStatus.on-time'),
		},
	};

	const statusOptions: { [key: string]: BaseSelectOptionType[] } = {
		[IssueStatusType.OPEN]: [statusMap[IssueStatusType.IN_PROGRESS]],
		[IssueStatusType.IN_PROGRESS]: [statusMap[IssueStatusType.IN_REVIEW], statusMap[IssueStatusType.BLOCKED]],
		[IssueStatusType.IN_REVIEW]: [
			statusMap[IssueStatusType.IN_PROGRESS],
			statusMap[IssueStatusType.BLOCKED],
			statusMap[IssueStatusType.RESOLVED],
		],
		[IssueStatusType.BLOCKED]: [statusMap[IssueStatusType.IN_REVIEW], statusMap[IssueStatusType.IN_PROGRESS]],
		[IssueStatusType.RESOLVED]: [statusMap[IssueStatusType.OPEN]],
	};

	const statusOptionsCF: { [key: string]: BaseSelectOptionType[] } = {
		[IssueStatusType.OPEN]: [statusMap[IssueStatusType.IN_PROGRESS]],
		[IssueStatusType.IN_PROGRESS]: [statusMap[IssueStatusType.RESOLVED]],
		[IssueStatusType.RESOLVED]: [statusMap[IssueStatusType.OPEN]],
	};

	return {
		questionnaireMap,
		surveyMap,
		userMap,
		departmentMap,
		siteMap,
		issueTypeMap,
		flagMap,
		priorityMap,
		statusMap,
		resolvedStatusMap,
		secondaryStatusMap,
		userDataVisibilityMap,
		departmentDataVisibilityMap,

		questionnaireOptions,
		surveyOptions,
		userOptions,
		departmentOptions,
		siteOptions,
		statusOptions,
		statusOptionsCF,
		userDataVisibilityOptions,
		departmentDataVisibilityOptions,
	};
};

export default useIssueDataOptions;

import { toast } from 'react-toastify';
import { fetchGalleryAttachments, searchGalleryAttachment } from 'services/gallery/gallery.service';
import { RootState } from 'store/rootReducers';
import { call, put, select } from 'typed-redux-saga';
import * as actions from './gallery.action';
import * as types from './gallery.actionTypes';
import { GalleryGroupByType, GalleryState } from './type.d';
import queryString from 'query-string';
import { AttachmentGalleryQueryOptions, AttachmentGalleryResponse } from '@nimbly-technologies/nimbly-common';
import { takeLatest } from 'redux-saga/effects';
import { initialSearchValues } from './gallery.reducer';
import { cloneDeep } from 'lodash';

function getQueryString(
	{ limit, groupBy, ...state }: GalleryState,
	queryObject?: Partial<AttachmentGalleryQueryOptions>,
) {
	const isSection = groupBy !== GalleryGroupByType.ALL;
	const hasSectionElements = isSection && !!Object.keys(state.sections).length;
	const hasAttachmentElements = !isSection && !!state.attachments.length;

	const page = hasSectionElements || hasAttachmentElements ? state.page : 1;
	const query: Partial<AttachmentGalleryQueryOptions> = { limit, page, groupBy, ...queryObject };

	if (state.searchQuery) {
		query.search = state.searchQuery;
	}

	for (const [key, value] of Object.entries(state.filters)) {
		if (value?.length) {
			query[key as keyof AttachmentGalleryQueryOptions] = value;
		}
	}

	return queryString.stringify(query);
}

function getResult(
	results: AttachmentGalleryResponse[] | Record<string, AttachmentGalleryResponse[]>,
	state: GalleryState,
	loadMore?: boolean,
) {
	const isSection = state.groupBy !== GalleryGroupByType.ALL;
	const attachments: AttachmentGalleryResponse[] = Array.isArray(results) ? results : [];
	const sections = isSection ? (results as Record<string, AttachmentGalleryResponse[]>) : {};

	if (!loadMore) {
		return { attachments, sections };
	}

	if (isSection) {
		const updatedSections = cloneDeep(state.sections);
		Object.entries(sections).forEach(([key, section]) => {
			updatedSections[key] = [...new Set([...(updatedSections[key] || []), ...section])];
		});

		return { attachments: [], sections: updatedSections };
	}

	return { attachments: [...state.attachments, ...attachments], sections: {} };
}

export function* fetchAttachmentsSaga({ payload }: ReturnType<typeof actions.fetchAttachments.request>) {
	const galleryState = (state: RootState) => state.gallery;
	const state: GalleryState = yield select(galleryState);

	try {
		const queryStr = getQueryString(state);
		const { results, ...rest } = yield* call(fetchGalleryAttachments, queryStr);
		const result = getResult(results, state, payload?.loadMore);
		const returnPayload = { ...rest, ...result };

		yield put(actions.fetchAttachments.success(returnPayload));
	} catch (e) {
		toast.error((e as Error).message);
		yield put(actions.fetchAttachments.failure({ error: 'Failed to fetch attachments' }));
	}
}

export function* searchAttachmentsSaga() {
	const galleryState = (state: RootState) => state.gallery;
	const state: GalleryState = yield select(galleryState);

	if (!state.searchQuery) {
		yield put(actions.searchAttachments.success(initialSearchValues));
		return;
	}

	try {
		const queryStr = getQueryString(state, {
			limit: undefined,
			page: undefined,
		});
		const result = yield* call(searchGalleryAttachment, queryStr);

		yield put(actions.searchAttachments.success(result));
	} catch (e) {
		toast.error((e as Error).message);
		yield put(actions.searchAttachments.failure({ error: 'Failed to get attachments esarch result' }));
	}
}

export default function* gallerySaga() {
	yield takeLatest(types.FETCH_ATTACHMENTS_REQUEST, fetchAttachmentsSaga);
	yield takeLatest(types.RESET_GALLERY_FILTERS, fetchAttachmentsSaga);

	yield takeLatest(types.SEARCH_ATTACHMENTS_REQUEST, searchAttachmentsSaga);
}

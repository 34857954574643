import React, { useEffect } from 'react';

import { InputLogin, InputLoginPassword } from './Auth.styles';
import { AuthFormProps } from './AuthForm';
import ReactGA from 'react-ga';

export type AuthFormLoginProps = Omit<
	AuthFormProps,
	| 'forgotPasswordData'
	| 'modeForgot'
	| 'handleSubmitForm'
	| 'handleChangeForgotPass'
	| 'handleToggleForgotPass'
	| 'validationMessage'
	| 'handleRequestAccess'
	| 'isRequestSuccess'
	| 'isRequestFailed'
	| 'isLoading'
	| 'isMultipleRequest'
	| 'handleDismissRequestSuccess'
>;

export const AuthFormLogin = (props: AuthFormLoginProps) => {
	const { siteFlashMessage, loginCredentials, isFederated, isRequestAccess, isEmailValid, isAuthenticating } = props;
	useEffect(() => {
		ReactGA.event({
			category: 'Home',
			action: 'Landing - Login',
		});
	});

	const hasErrorEmail = siteFlashMessage.hasError.email && loginCredentials.email !== undefined;
	const hasErrorPassword = siteFlashMessage.hasError.password && loginCredentials.password !== undefined;
	const inputType = !isFederated && isEmailValid ? 'password' : 'hidden';

	return (
		<div className="fade-in">
			<InputLogin
				data-testid="email-input"
				name="email"
				placeholder="Email Address"
				hasError={hasErrorEmail}
				value={props.loginCredentials.email}
				type="email"
				required
				disabled={isRequestAccess || isAuthenticating}
				onChange={props.handleChangeEmail}
				id="input_username_login"
			/>

			<InputLoginPassword
				data-testid="password-input"
				name="password"
				placeholder="Password"
				hasError={hasErrorPassword || hasErrorEmail}
				value={props.loginCredentials.password}
				required
				type={inputType}
				onChange={props.handleChangePassword}
				disabled={props.isCheckingEmail}
				id="input_pwd_login"
			/>
		</div>
	);
};

export default AuthFormLogin;

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getToken } from 'reducers/api';
import { Department, DepartmentIndex } from 'nimbly-common';

import { apiURL, cloudV2ApiURL } from 'config/baseURL';
import * as types from 'reducers/departments/departments.actionTypes';
import * as actions from 'reducers/departments/departments.action';
import { RootState } from 'store/rootReducers';
import { DepartmentIndexWithMap } from 'models/departmentIndex';
import API from 'helpers/api';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { setToSession, checkExpiredStorageItem } from 'helpers/sessionStorageHelper';

const DEPARTMENTS = 'DEPARTMENTS';

function* fetchDepartmentsIndex() {
	try {
		const authToken = yield call(API.getFirebaseToken);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchDepartmentIndexURL = `${apiURL}/departments/index/`;
		const request = () => fetch(fetchDepartmentIndexURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();

			const mappingData: { [key: string]: DepartmentIndex } = {};

			responseData.data.forEach((department: DepartmentIndex) => {
				const departmentKey: string = department.departmentID;
				if (!mappingData.hasOwnProperty(departmentKey)) {
					const userMap: { [uid: string]: number } = {};
					const siteMap: { [siteKey: string]: boolean } = {};
					const questionnaireMap: { [questionnaireKey: string]: boolean } = {};
					department.users.forEach((user: { uid: string; level: number }) => {
						userMap[user.uid] = user.level;
					});
					department.sites.forEach((site: { id: string }) => {
						siteMap[site.id] = true;
					});
					department.questionnaires.forEach((questionnaire: { id: string }) => {
						questionnaireMap[questionnaire.id] = true;
					});
					const departmentWithMap: DepartmentIndexWithMap = {
						userMap,
						siteMap,
						questionnaireMap,
						...department,
					};
					mappingData[departmentKey] = departmentWithMap;
				}
			});

			yield put(actions.fetchDepartmentsIndex.success({ data: mappingData }));
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchDepartmentsIndex.failure({ error: responseData.message }));
		}
	} catch (e) {
		yield put(actions.fetchDepartmentsIndex.success({ data: null }));
		yield put(actions.fetchDepartmentsIndex.failure({ error: 'Failed to Fetch Departments' }));
	}
}

function* fetchDepartmentIndexById(action: ReturnType<typeof actions.fetchDepartmentIndexById.request>) {
	try {
		const deptID = action.payload.deptID;
		const authToken = yield call(API.getFirebaseToken);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchDepartmentIndexURL = `${apiURL}/departments/index/${deptID}`;
		const request = () => fetch(fetchDepartmentIndexURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();
			yield put(actions.fetchDepartmentIndexById.success({ data: responseData.data }));
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchDepartmentIndexById.failure({ error: responseData.message }));
		}
	} catch (e) {
		yield put(actions.fetchDepartmentIndexById.success({ data: null }));
		yield put(actions.fetchDepartmentIndexById.failure({ error: 'Failed to Fetch Department' }));
	}
}

function* fetchDepartmentIndexWithUserRolesById(
	action: ReturnType<typeof actions.fetchDepartmentIndexWithUserRolesById.request>,
) {
	try {
		const deptID = action.payload.deptID;
		const authToken = yield call(API.getFirebaseToken);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};
		const fetchDepartmentIndexURL = `${cloudV2ApiURL}/admin/department-indexes/${deptID}?qType=user-role-in-site`;
		const request = () => fetch(fetchDepartmentIndexURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();
			yield put(actions.fetchDepartmentIndexWithUserRolesById.success({ data: responseData.data }));
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchDepartmentIndexWithUserRolesById.failure({ error: responseData.message }));
		}
	} catch (e) {
		yield put(actions.fetchDepartmentIndexWithUserRolesById.success({ data: null }));
		yield put(actions.fetchDepartmentIndexWithUserRolesById.failure({ error: 'Failed to Fetch Department' }));
	}
}

function* updateDepartmentIndexWithUserRolesById(
	action: ReturnType<typeof actions.updateDepartmentIndexWithUserRolesById.request>,
) {
	try {
		const authToken = yield call(API.getFirebaseToken);

		const options = {
			method: 'PUT',
			headers: {
				Authorization: authToken,
			},
			body: JSON.stringify(action.payload),
		};
		const updateDepartmentIndexURL = `${cloudV2ApiURL}/admin/sites/replace-supervisors`;
		const request = () => fetch(updateDepartmentIndexURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			toast.success(i18n.t('message.departmentPage.updateReplaceSupervisor.success'));
			const responseData = yield response.json();
			yield put(actions.updateDepartmentIndexWithUserRolesById.success(responseData.data));
		} else {
			const responseData = yield response.json();
			yield put(actions.updateDepartmentIndexWithUserRolesById.failure(responseData.message));
		}
	} catch (e) {
		toast.success(i18n.t('message.departmentPage.updateReplaceSupervisor.failed'));
		yield put(actions.updateDepartmentIndexWithUserRolesById.failure({ error: 'Failed to update Department' }));
		return null;
	}
}

export function* fetchDepartments() {
	try {
		yield put(actions.setLoading(true));
		const authToken = yield call(API.getFirebaseToken);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const deps = checkExpiredStorageItem<any>(DEPARTMENTS);
		if (!deps) {
			const fetchDepartmetsURL = `${apiURL}/departments`;
			const request = () => fetch(fetchDepartmetsURL, options);
			const response = yield call(request);

			if (response && response.status === 200) {
				const responseData = yield response.json();
				setToSession(responseData, DEPARTMENTS);
				const mappingData: { [key: string]: Department } = {};

				responseData.data.forEach((department: Department) => {
					const departmentKey: string = department.departmentID;
					if (!mappingData.hasOwnProperty(departmentKey)) {
						mappingData[departmentKey] = department;
					}
				});

				yield put(actions.fetchDepartments.success({ data: mappingData }));
				return mappingData;
			} else {
				const responseData = yield response.json();
				yield put(actions.fetchDepartments.failure({ error: responseData.message }));
				return null;
			}
		} else {
			const responseData = deps;
			const mappingData: { [key: string]: Department } = {};

			responseData.data.forEach((department: Department) => {
				const departmentKey: string = department.departmentID;
				if (!mappingData.hasOwnProperty(departmentKey)) {
					mappingData[departmentKey] = department;
				}
			});

			yield put(actions.fetchDepartments.success({ data: mappingData }));
			return mappingData;
		}
	} catch (e) {
		yield put(actions.fetchDepartments.success({ data: null }));
		yield put(actions.fetchDepartments.failure({ error: 'Failed to Fetch Departments' }));
		return null;
	}
}

export function* fetchDepartmentsUserFilterOptions() {
	try {
		yield put(actions.setLoading(true));
		const authToken = yield call(API.getFirebaseToken);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};
		const fetchDepartmetsURL = `${apiURL}/departments/user-filter-options?applyVisibility=false`;
		const request = () => fetch(fetchDepartmetsURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();
			const mappingData: { [key: string]: Department } = {};

			responseData.data.forEach((department: Department) => {
				const departmentKey: string = department.departmentID;
				if (!mappingData.hasOwnProperty(departmentKey)) {
					mappingData[departmentKey] = department;
				}
			});

			yield put(actions.fetchDepartmentsUserFilterOptions.success({ data: mappingData }));
			return mappingData;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchDepartmentsUserFilterOptions.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchDepartmentsUserFilterOptions.success({ data: null }));
		yield put(actions.fetchDepartmentsUserFilterOptions.failure({ error: 'Failed to Fetch Departments' }));
		return null;
	}
}

export function* fetchPaginateDepartments(action: ReturnType<typeof actions.fetchPaginateDepartments.request>) {
	try {
		const authToken = yield getToken();
		const getState = (state: RootState) => state;
		const state: RootState = yield select(getState);

		const departmentState: any = state.departments;

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};
		let sortDirections = 'asc';
		if (departmentState.sortBy === 'name') {
			sortDirections = departmentState.name;
		} else if (departmentState.sortBy === 'key') {
			sortDirections = departmentState.key;
		} else if (departmentState.sortBy === 'description') {
			sortDirections = departmentState.description;
		} else if (departmentState.sortBy === 'email') {
			sortDirections = departmentState.email;
		}

		const query: { [key: string]: string | number } = {
			search: departmentState.filterQuery || '',
			page: departmentState.pageIndex,
			sortFields: departmentState.sortBy === 'key' ? 'departmentID' : departmentState.sortBy,
			sortDirections,
			limit: action.payload.limit,
		};

		const queryStr = Object.keys(query).length
			? Object.keys(query)
					.map((key: string) => {
						return `${key}=${query[key]}`;
					})
					.join('&')
			: '';
		let deptStatus = '&status=active';
		if (departmentState.selectedTab === 'disabled') {
			deptStatus = '&status=disabled';
		}

		const fetchDepartmetsURL = `${apiURL}/departments/paginate?${queryStr}${deptStatus}`;
		const request = () => fetch(fetchDepartmetsURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();

			const mappingData: { [key: string]: Department } = {};

			responseData.data.docs.forEach((department: Department) => {
				const departmentKey: string = department.departmentID;
				if (!mappingData.hasOwnProperty(departmentKey)) {
					mappingData[departmentKey] = department;
				}
			});

			yield put(actions.fetchPaginateDepartments.success({ data: mappingData, total: responseData.data.totalDocs }));
			return mappingData;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchPaginateDepartments.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchPaginateDepartments.success({ data: null, total: null }));
		yield put(actions.fetchPaginateDepartments.failure({ error: 'Failed to Fetch Sites' }));
		return null;
	}
}

export function* fetchUserSchedule() {
	try {
		const token = yield getToken();
		const getState = (state: RootState) => state;
		const state: RootState = yield select(getState);
		const departmentState: any = state.departments;
		const options = {
			method: 'GET',
			headers: {
				Authorization: token,
			},
		};
		const id = departmentState.selectedDepartmentDeleteKey;
		const url = `${apiURL}/schedules/departments/${id}`;
		const request = () => fetch(url, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const result = yield response.json();
			yield put(actions.fetchDepartmentSchedule.success({ data: result.data }));
			return null;
		} else {
			const result = yield response.json();
			yield put(actions.fetchDepartmentSchedule.failure({ error: result.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchDepartmentSchedule.failure({ error: 'Failed to Fetch Department Schedule' }));
	}
}

export function* fetchDepartmentsDataVisibility() {
	try {
		yield put(actions.setLoading(true));
		const authToken = yield call(API.getFirebaseToken);

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchDepartmetsURL = `${apiURL}/departments?withDataVisibility=false`;
		const request = () => fetch(fetchDepartmetsURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();
			setToSession(responseData, DEPARTMENTS);
			const mappingData: { [key: string]: Department } = {};

			responseData.data.forEach((department: Department) => {
				const departmentKey: string = department.departmentID;
				if (!mappingData.hasOwnProperty(departmentKey)) {
					mappingData[departmentKey] = department;
				}
			});

			yield put(actions.fetchDepartmentsDataVisibility.success({ data: mappingData }));
			return mappingData;
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchDepartmentsDataVisibility.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.fetchDepartmentsDataVisibility.success({ data: null }));
		yield put(actions.fetchDepartmentsDataVisibility.failure({ error: 'Failed to Fetch Departments' }));
		return null;
	}
}

export default function* departmentsSaga() {
	yield takeLatest(types.FETCH_DEPARTMENTS_INDEX_REQUEST, fetchDepartmentsIndex);
	yield takeLatest(types.FETCH_DEPARTMENT_INDEX_BY_ID_REQUEST, fetchDepartmentIndexById);
	yield takeLatest(types.FETCH_DEPARTMENTS_REQUEST, fetchDepartments);
	yield takeLatest(types.FETCH_DEPARTMENTS_USER_FILTER_REQUEST, fetchDepartmentsUserFilterOptions);
	yield takeLatest(types.FETCH_PAGINATE_DEPARTMENTS_REQUEST, fetchPaginateDepartments);
	yield takeLatest(types.FETCH_DEPARTMENT_SCHEDULE_REQUEST, fetchUserSchedule);
	yield takeLatest(types.UPDATE_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_REQUEST, updateDepartmentIndexWithUserRolesById);
	yield takeLatest(types.FETCH_DEPARTMENT_INDEX_WITH_ROLES_BY_ID_REQUEST, fetchDepartmentIndexWithUserRolesById);
	yield takeLatest(types.FETCH_DEPARTMENTS_DATA_VISIBILITY_REQUEST, fetchDepartmentsDataVisibility);
}

export const SET_FILTERS = '@adminDashboard/SET_FILTERS';
export const SET_ORG_ID = '@adminDashboard/SET_ORG_ID';
export const SET_SHOW_ERROR_MODAL = '@adminDashboard/SET_SHOW_ERROR_MODAL';
export const SET_SELECTED_ORG = '@adminDashboard/SET_SELECTED_ORG';

export const FETCH_TOTAL_ACTIVE_SITES_REQUEST = '@adminDashboard/FETCH_TOTAL_ACTIVE_SITES_REQUEST';
export const FETCH_TOTAL_ACTIVE_SITES_SUCCESS = '@adminDashboard/FETCH_TOTAL_ACTIVE_SITES_SUCCESS';
export const FETCH_TOTAL_ACTIVE_SITES_FAILURE = '@adminDashboard/FETCH_TOTAL_ACTIVE_SITES_FAILURE';

export const FETCH_TOTAL_ACTIVE_USERS_REQUEST = '@adminDashboard/FETCH_TOTAL_ACTIVE_USERS_REQUEST';
export const FETCH_TOTAL_ACTIVE_USERS_SUCCESS = '@adminDashboard/FETCH_TOTAL_ACTIVE_USERS_SUCCESS';
export const FETCH_TOTAL_ACTIVE_USERS_FAILURE = '@adminDashboard/FETCH_TOTAL_ACTIVE_USERS_FAILURE';

export const FETCH_TOTAL_LOGIN_DEVICES_REQUEST = '@adminDashboard/FETCH_TOTAL_LOGIN_DEVICES_REQUEST';
export const FETCH_TOTAL_LOGIN_DEVICES_SUCCESS = '@adminDashboard/FETCH_TOTAL_LOGIN_DEVICES_SUCCESS';
export const FETCH_TOTAL_LOGIN_DEVICES_FAILURE = '@adminDashboard/FETCH_TOTAL_LOGIN_DEVICES_FAILURE';

export const FETCH_AVG_LOGIN_INFO_REQUEST = '@adminDashboard/FETCH_AVG_LOGIN_INFO_REQUEST';
export const FETCH_AVG_LOGIN_INFO_SUCCESS = '@adminDashboard/FETCH_AVG_LOGIN_INFO_SUCCESS';
export const FETCH_AVG_LOGIN_INFO_FAILURE = '@adminDashboard/FETCH_AVG_LOGIN_INFO_FAILURE';

export const FETCH_USERS_CREATED_V_UPDATED_REQUEST = '@adminDashboard/FETCH_USERS_CREATED_V_UPDATED_REQUEST';
export const FETCH_USERS_CREATED_V_UPDATED_SUCCESS = '@adminDashboard/FETCH_USERS_CREATED_V_UPDATED_SUCCESS';
export const FETCH_USERS_CREATED_V_UPDATED_FAILURE = '@adminDashboard/FETCH_USERS_CREATED_V_UPDATED_FAILURE';

export const FETCH_SITES_CREATED_V_UPDATED_REQUEST = '@adminDashboard/FETCH_SITES_CREATED_V_UPDATED_REQUEST';
export const FETCH_SITES_CREATED_V_UPDATED_SUCCESS = '@adminDashboard/FETCH_SITES_CREATED_V_UPDATED_SUCCESS';
export const FETCH_SITES_CREATED_V_UPDATED_FAILURE = '@adminDashboard/FETCH_SITES_CREATED_V_UPDATED_FAILURE';

export const FETCH_AVG_V_TOTAL_REPORTS_REQUEST = '@adminDashboard/FETCH_AVG_V_TOTAL_REPORTS_REQUEST';
export const FETCH_AVG_V_TOTAL_REPORTS_SUCCESS = '@adminDashboard/FETCH_AVG_V_TOTAL_REPORTS_SUCCESS';
export const FETCH_AVG_V_TOTAL_REPORTS_FAILURE = '@adminDashboard/FETCH_AVG_V_TOTAL_REPORTS_FAILURE';

export const FETCH_MAU_REQUEST = '@adminDashboard/FETCH_MAU_REQUEST';
export const FETCH_MAU_SUCCESS = '@adminDashboard/FETCH_MAU_SUCCESS';
export const FETCH_MAU_FAILURE = '@adminDashboard/FETCH_MAU_FAILURE';

export const FETCH_STORAGE_REQUEST = '@adminDashboard/FETCH_STORAGE_REQUEST';
export const FETCH_STORAGE_SUCCESS = '@adminDashboard/FETCH_STORAGE_SUCCESS';
export const FETCH_STORAGE_FAILURE = '@adminDashboard/FETCH_STORAGE_FAILURE';

export const SET_IS_BUSY = '@questionnaireDetail/SET_IS_BUSY';
export const SET_IS_PAGE_LOADED = '@questionnaireDetail/SET_IS_PAGE_LOADED';

export const SET_ALLOWED_REFERENCE = '@questionnaireDetail/SET_ALLOWED_REFERENCE';
export const SET_ALLOWED_REFERENCE_BATCH = '@questionnaireDetail/SET_ALLOWED_REFERENCE_BATCH';
export const SET_ALLOWED_CONDITIONAL_REFERENCE = '@questionnaireDetail/SET_ALLOWED_CONDITIONAL_REFERENCE';
export const SET_ALLOWED_CONDITIONAL_REFERENCE_BATCH = '@questionnaireDetail/SET_ALLOWED_CONDITIONAL_REFERENCE_BATCH';

export const SET_ALLOWED_TAG = '@questionnaireDetail/SET_ALLOWED_TAG';
export const SET_ALLOWED_TAG_BATCH = '@questionnaireDetail/SET_ALLOWED_TAG_BATCH';
export const SET_ALLOWED_CONDITIONAL_TAG = '@questionnaireDetail/SET_ALLOWED_CONDITIONAL_TAG';
export const SET_ALLOWED_CONDITIONAL_TAG_BATCH = '@questionnaireDetail/SET_ALLOWED_CONDITIONAL_TAG_BATCH';

export const SET_ALLOWED_RECOMMENDATION = '@questionnaireDetail/SET_ALLOWED_RECOMMENDATION';
export const SET_ALLOWED_RECOMMENDATION_BATCH = '@questionnaireDetail/SET_ALLOWED_RECOMMENDATION_BATCH';
export const SET_ALLOWED_CONDITIONAL_RECOMMENDATION = '@questionnaireDetail/SET_ALLOWED_CONDITIONAL_RECOMMENDATION';
export const SET_ALLOWED_CONDITIONAL_RECOMMENDATION_BATCH =
	'@questionnaireDetail/SET_ALLOWED_CONDITIONAL_RECOMMENDATION_BATCH';

export const SET_ALLOWED_ATTACHMENT = 'questionnaireDetail/SET_ALLOWED_ATTACHMENT';
export const SET_ALLOWED_CONDITIONAL_ATTACHMENT = 'questionnaireDetail/SET_ALLOWED_CONDITIONAL_ATTACHMENT';
export const SET_ALLOWED_ATTACHMENT_BATCH = 'questionnaireDetail/SET_ALLOWED_ATTACHMENT_BATCH';
export const SET_ALLOWED_CONDITIONAL_ATTACHMENT_BATCH = 'questionnaireDetail/SET_ALLOWED_CONDITIONAL_ATTACHMENT_BATCH';
export const SET_AUTO_ASSIGNMENTV2 = '@questionnaireDetail/SET_AUTO_ASSIGNMENTV2';

export const RESET_STATE = '@questionnaires/RESET_STATE';
export const RESET_STATE_CUSTOMER_FEEDBACK = '@questionnaires/RESET_STATE_CUSTOMER_FEEDBACK';
export const SET_CATEGORIES = '@questionnaireDetail/SET_CATEGORIES';
export const SET_CATEGORY_IDS = '@questionnaireDetail/SET_CATEGORY_IDS';
export const SET_VIDEO_SET = '@questionnaireDetail/SET_VIDEO_SET';
export const SET_QUESTION_WITH_VIDEO = '@questionnaireDetail/SET_QUESTION_WITH_VIDEO';
export const SET_QUESTION_WITH_SCORE = '@questionnaireDetail/SET_QUESTION_WITH_SCORE';
export const SET_TOTAL_SCORE_WEIGHT = '@questionnaireDetail/SET_TOTAL_SCORE_WEIGHT';
export const SET_EDITING_QUESTIONS = '@questionnaireDetail/SET_EDITING_QUESTIONS';
export const SET_EDITING_CONDITIONAL_QUESTIONS = '@questionnaireDetail/SET_EDITING_CONDITIONAL_QUESTIONS';

export const SET_INVENTORY_QUESTIONS = '@questionnaireDetail/SET_INVENTORY_QUESTIONS';
export const SET_INVENTORY_INDEX = '@questionnaireDetail/SET_INVENTORY_INDEX';
export const SET_SELECTED_RANGE_FLAG_TYPE = '@questionnaireDetail/SET_SELECTED_RANGE_FLAG_TYPE';
export const SET_SELECTED_CONDITIONAL_RANGE_FLAG_TYPE = '@questionnaireDetail/SET_SELECTED_CONDITIONAL_RANGE_FLAG_TYPE';
export const SET_SELECTED_RANGE_FLAG_TYPE_BATCH = '@questionnaireDetail/SET_SELECTED_RANGE_FLAG_TYPE_BATCH';
export const SET_SELECTED_CONDITIONAL_RANGE_FLAG_TYPE_BATCH =
	'@questionnaireDetail/SET_SELECTED_CONDITIONAL_RANGE_FLAG_TYPE_BATCH';
export const SET_IS_RANGE_FLAG_INVERTED = '@questionnaireDetail/SET_IS_RANGE_FLAG_INVERTED';
export const SET_IS_EDITABLE = '@questionnaireDetail/SET_IS_EDITABLE';
export const SET_IS_CONDITIONAL_RANGE_FLAG_INVERTED = '@questionnaireDetail/SET_IS_CONDITIONAL_RANGE_FLAG_INVERTED';
export const SET_IS_RANGE_FLAG_INVERTED_BATCH = '@questionnaireDetail/SET_IS_RANGE_FLAG_INVERTED_BATCH';
export const SET_IS_CONDITIONAL_RANGE_FLAG_INVERTED_BATCH =
	'@questionnaireDetail/SET_IS_CONDITIONAL_RANGE_FLAG_INVERTED_BATCH';

export const SET_CHECK_FIELD = '@questionnaireDetail/SET_CHECK_FIELD';
export const SET_TITLE = '@questionnaireDetail/SET_TITLE';
export const SET_QUESTIONS = '@questionnaireDetail/SET_QUESTIONS';
export const SET_CONDITIONAL_QUESTIONS = '@questionnaireDetail/SET_CONDITIONAL_QUESTIONS';
export const SET_DATE_CREATED = '@questionnaireDetail/SET_DATE_CREATED';
export const SET_DATE_UPDATED = '@questionnaireDetail/SET_DATE_UPDATED';
export const SET_QUESTIONNAIRE_INDEX = '@questionnaireDetail/SET_QUESTIONNAIRE_INDEX';
export const SET_MODIFIED_BY = '@questionnaireDetail/SET_MODIFIED_BY';
export const SET_FLAG_LABEL = '@questionnaireDetail/SET_FLAG_LABEL';
export const SET_CATEGORY_LABEL_FLAGS = '@questionnaireDetail/SET_CATEGORY_LABEL_FLAGS';
export const SET_QUESTIONNAIRE_DEPARTMENTS = '@questionnaireDetail/SET_QUESTIONNAIRE_DEPARTMENTS';
export const SET_QUESTIONNAIRE_DEPT_LABEL = '@questionnaireDetail/SET_QUESTIONNAIRE_DEPT_LABEL';
export const SHOW_QUESTIONNAIRE_AUTO_ASSIGNMENT_MODAL = '@questionnaireDetail/SHOW_QUESTIONNAIRE_AUTO_ASSIGNMENT_MODAL';
export const CLOSE_QUESTIONNAIRE_AUTO_ASSIGNMENT_MODAL =
	'@questionnaireDetail/CLOSE_QUESTIONNAIRE_AUTO_ASSIGNMENT_MODAL';
export const SET_APPLY_ALL_CATEGORY = '@questionnaireDetail/SET_APPLY_ALL_CATEGORY';
export const SET_AUTO_ASSIGNMENT = '@questionnaireDetail/SET_AUTO_ASSIGNMENT';

export const SET_PASS_CRITERIA = '@questionnaireDetail/SET_PASS_CRITERIA';
export const SET_PASS_CRITERIA_VALUE = '@questionnaireDetail/SET_PASS_CRITERIA_VALUE';
export const SET_PASS_CRITERIA_MIN_MAX_VALUE = '@questionnaireDetail/SET_PASS_CRITERIA_MIN_MAX_VALUE';
export const SET_PASS_CRITERIA_TYPE = '@questionnaireDetail/SET_PASS_CRITERIA_TYPE';
export const SET_MAX_SCORE = '@questionnaireDetail/SET_MAX_SCORE';
export const SET_USE_DEDUCTIONS = '@questionnaireDetail/SET_USE_DEDUCTIONS';

export const SET_INDEPENDENT_ISSUE = '@questionnaireDetail/SET_INDEPENDENT_ISSUE';
export const SET_INDEPENDENT_ISSUE_QUESTIONNAIRE = '@questionnaireDetail/SET_INDEPENDENT_ISSUE_QUESTIONNAIRE';

import { IssueTrackerSettings, IssueTrackerSettingsRequest } from '@nimbly-technologies/nimbly-common';
import { apiURL } from '../../../config/baseURL';
import API from '../../../helpers/api';
import {
	CreateAutoDueDatePayload,
	CreateAutoDueDateResponse,
	GetAutoDueDatesResponse,
	GetDefaultAutoDueDateResponse,
	Site,
	UpdateAutoDueDatePayload,
	UpdateAutoDueDateResponse,
} from './issueTrackerSettingsUtils.types';
import debounce from 'lodash/debounce';

export type DueDateSettingType = 'manual' | 'automatic';

export const getIssueTrackerSetting = async (organizationId: string): Promise<IssueTrackerSettings> => {
	const url = `${apiURL}/organizations/${organizationId}/issue-tracker-settings`;
	const token = await API.getFirebaseToken();
	const result = await API.getRetry(url, token);

	const response = await result.json();
	if (result.status === 200) {
		return response.data as IssueTrackerSettings;
	} else if (result.status === 404) {
		return createDefaultIssueTrackerSetting(organizationId);
	} else {
		throw new Error();
	}
};

export const createDefaultIssueTrackerSetting = async (organizationId: string): Promise<IssueTrackerSettings> => {
	const url = `${apiURL}/organizations/${organizationId}/issue-tracker-settings`;
	const token = await API.getFirebaseToken();
	const result = await API.post(url, token, {
		useAutoDueDate: false,
	});

	const response = await result.json();
	if (result.status === 200) {
		return response.data as IssueTrackerSettings;
	} else {
		throw new Error();
	}
};

export const updateIssueTrackerSetting = async (
	organizationId: string,
	issueTrackerSettingsId: string,
	payload: IssueTrackerSettingsRequest,
): Promise<IssueTrackerSettings> => {
	const url = `${apiURL}/organizations/${organizationId}/issue-tracker-settings/${issueTrackerSettingsId}`;
	const token = await API.getFirebaseToken();
	const result = await API.put(url, token, payload);

	const response = await result.json();
	if (result.status === 200) {
		return response.data as IssueTrackerSettings;
	} else {
		throw new Error();
	}
};

export const getAutoDueDates = async (searchQuery = '', page = 1, limit = 15): Promise<GetAutoDueDatesResponse> => {
	const params = new URLSearchParams({ search: searchQuery, page: `${page}`, limit: `${limit}` });
	const url = `${apiURL}/issues/auto-due-dates?${params}`;

	const token = await API.getFirebaseToken();
	const result = await API.get(url, token);

	const response = await result.json();
	if (result.status === 200) {
		return response.data as GetAutoDueDatesResponse;
	} else {
		throw new Error();
	}
};

export const debounceGetAutoDueDates = debounce(getAutoDueDates, 200);

export const getSites = async (): Promise<Site[]> => {
	const url = `${apiURL}/sites/sites-for-autoduedate`;

	const token = await API.getFirebaseToken();
	const result = await API.get(url, token);

	const response = await result.json();
	if (result.status === 200) {
		return response.data as Site[];
	} else {
		throw new Error();
	}
};

export const createAutoDueDate = async (payload: CreateAutoDueDatePayload): Promise<CreateAutoDueDateResponse> => {
	const url = `${apiURL}/issues/auto-due-dates`;
	const token = await API.getFirebaseToken();
	const result = await API.post(url, token, payload);

	const response = await result.json();
	if (result.status === 200) {
		return response.data as CreateAutoDueDateResponse;
	} else {
		throw new Error();
	}
};

export const updateAutoDueDate = async (payload: UpdateAutoDueDatePayload): Promise<UpdateAutoDueDateResponse> => {
	const url = `${apiURL}/issues/auto-due-dates/${payload.autoDueDateID}`;
	const token = await API.getFirebaseToken();
	const result = await API.put(url, token, payload);

	const response = await result.json();
	if (result.status === 200) {
		return response.data as UpdateAutoDueDateResponse;
	} else {
		throw new Error();
	}
};

export const deleteAutoDueDate = async (autoDueDateId: string): Promise<boolean> => {
	const url = `${apiURL}/issues/auto-due-dates/${autoDueDateId}`;
	const token = await API.getFirebaseToken();
	const result = await API.delete(url, token);
	if (result.status === 200) {
		return true;
	} else {
		throw new Error();
	}
};

export const getDefaultAutoDueDate = async (): Promise<GetDefaultAutoDueDateResponse | undefined> => {
	const url = `${apiURL}/issues/auto-due-dates/default`;

	const token = await API.getFirebaseToken();
	const result = await API.get(url, token);

	const response = await result.json();
	if (result.status === 200) {
		return response.data as GetDefaultAutoDueDateResponse;
	} else if (result.status === 404) {
		return undefined;
	} else {
		throw new Error();
	}
};

/* eslint-disable  */
import API from 'helpers/api';
import { appengineURL } from 'config/baseURL';
import { store } from 'store/configStore';
import queryString from 'query-string';
import {
	ReportType,
	ReportsTableData,
	ReportCountDetails,
	ScheduleStatus,
} from 'components/reportshub/ReportHub.types';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { TableRow } from 'components/global/Table/Table.types';
import { SavedFiltersI } from 'reducers/reportsHub/reportsHub.types';
import moment from 'moment';

export const fetchReportList = async (): Promise<ReportsTableData | undefined> => {
	try {
		const {
			tableType,
			reportsTable: { limit, page, sortDirections, sortFields },
		} = store.getState().reportsHub;

		let query = queryString.stringify({
			limit,
			page,
			sortDirections,
			sortFields,
		});

		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		};

		const url = `${appengineURL}/bulkdownloadrequests/v2/${tableType}?${query}`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		const responseData = await response.json();
		return responseData.data as ReportsTableData;
	} catch (error) {
		toast.error(error);
	}
};

export const fetchReportCount = async (): Promise<ReportCountDetails | undefined> => {
	try {
		const { filters } = store.getState().reportsHub;

		let apiRoute = '';
		let body: any = {
			departments: filters?.departments?.length ? filters?.departments : ['_all_'], // department ids
			sites: filters?.sites?.length ? filters?.sites : ['_all_'], // site ids
			auditors: filters?.users?.length ? filters?.users : ['_all_'], // auditor ids
			questionnaires: filters?.questionnaires?.length ? filters?.questionnaires : ['_all_'], // questionnaire ids
			startDate: filters.startDate ? filters.startDate.format('YYYY-MM-DD') : null,
			endDate: filters.endDate ? filters.endDate.format('YYYY-MM-DD') : null,
			reportFormat: filters.reportFormat,
			isConsolidated: filters.isConsolidated,
			isWithAttachments: filters.isWithAttachments,
		};

		if (filters.reportType === ReportType.SCHEDULE) {
			if (filters.scheduleStatus === ScheduleStatus.MISSED) {
				apiRoute = 'reports/v2/missed-report';
				// @ts-ignore
				body['type'] = 'count';
			} else {
				apiRoute = 'reports/v2/compiled-reports/count';
			}
		} else if (filters.reportType === ReportType.ISSUE) {
			apiRoute = 'issues/v2/compiled-issues-count';
			body['issueStatus'] = filters?.issueStatus?.length ? filters.issueStatus : [];
			body['issueSeverity'] = filters.issueSeverity?.length ? filters.issueSeverity : [];
			body['issuePriority'] = filters.issuePriority?.length ? filters.issuePriority : [];
		} else if (filters.reportType === ReportType.INVENTORY) {
			apiRoute = 'inventory/count';
		}

		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const url = `${appengineURL}/${apiRoute}`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		const responseData = await response.json();
		return responseData.data as ReportCountDetails;
	} catch (error) {
		toast.error(error);
	}
};

export const fetchDownloadReport = async (): Promise<any | undefined> => {
	try {
		const { filters, reportsCount } = store.getState().reportsHub;

		let apiRoute = '';
		let body: any = {
			dJobID: uuidv4(),
			departments: filters?.departments?.length ? filters?.departments : ['_all_'],
			sites: filters?.sites?.length ? filters?.sites : ['_all_'],
			auditors: filters?.users?.length ? filters?.users : ['_all_'],
			questionnaires: filters?.questionnaires?.length ? filters?.questionnaires : ['_all_'],
			startDate: filters.startDate ? filters.startDate.format('YYYY-MM-DD') : null,
			endDate: filters.endDate ? filters.endDate.format('YYYY-MM-DD') : null,
			reportFormat: filters.reportFormat,
			isConsolidated: filters.isConsolidated,
			isWithAttachments: filters.isWithAttachments,
			reportsCount: reportsCount?.data?.reportsCount,
			recordCount: reportsCount?.data?.recordsCount,
		};

		if (filters.reportType === ReportType.SCHEDULE) {
			if (filters.scheduleStatus === ScheduleStatus.MISSED) {
				apiRoute = 'reports/v2/missed-report';
				body['type'] = 'file';
			} else {
				apiRoute = 'reports/v2/compiled-reports';
			}
		} else if (filters.reportType === ReportType.ISSUE) {
			apiRoute = 'issues/v2/compiled-issues';
			body['issueStatus'] = filters?.issueStatus?.length ? filters.issueStatus : [];
			body['issueSeverity'] = filters.issueSeverity?.length ? filters.issueSeverity : [];
			body['issuePriority'] = filters.issuePriority?.length ? filters.issuePriority : [];
		} else if (filters.reportType === ReportType.INVENTORY) {
			apiRoute = 'inventory/report';
		}

		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const url = `${appengineURL}/${apiRoute}`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		toast.success('Report request sent successsfully');
		const responseData = await response.json();
		return responseData;
	} catch (error) {
		toast.error(error);
	}
};

export const fetchRetryDownloadReport = async (rowData: TableRow): Promise<any | undefined> => {
	try {
		let apiRoute = '';
		let body: any = {
			dJobID: rowData?.value?.dJobID,
			departments: rowData?.value?.departments || ['_all_'],
			sites: rowData?.value?.sites || ['_all_'],
			auditors: rowData?.value?.auditors || ['_all_'],
			questionnaires: rowData?.value?.questionnaires || ['_all_'],
			startDate: rowData?.value?.startDate ? moment(rowData?.value?.startDate).format('YYYY-MM-DD') : null,
			endDate: rowData?.value?.endDate ? moment(rowData?.value?.endDate).format('YYYY-MM-DD') : null,
			reportFormat: rowData?.value?.reportFormat,
			isConsolidated: rowData?.value?.isConsolidated,
			isWithAttachments: rowData?.value?.isWithAttachments,
			reportsCount: rowData?.value?.reportsCount,
			status: 'failed',
		};
		if (rowData?.value?.reportType === 'report-completed') {
			apiRoute = 'reports/v2/compiled-reports';
		} else if (rowData?.value?.reportType === 'report-missing') {
			apiRoute = 'reports/v2/missed-report';
		} else if (rowData?.value?.reportType === 'issue') {
			apiRoute = 'issues/v2/compiled-issues';

			body['issueStatus'] = rowData?.value?.issueStatus?.length ? rowData?.value?.issueStatus : [];
			body['issueSeverity'] = rowData?.value?.issueSeverity?.length ? rowData?.value?.issueSeverity : [];
			body['issuePriority'] = rowData?.value?.issuePriority?.length ? rowData?.value?.issuePriority : [];
		} else if (rowData?.value?.reportType === 'inventory') {
			apiRoute = 'inventory/report';
		}

		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		};

		const url = `${appengineURL}/${apiRoute}`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		toast.success('Report retry request sent successsfully');
		const responseData = await response.json();
		return responseData;
	} catch (error) {
		toast.error(error);
	}
};

export const saveReportsCustomFilter = async (payload: any) => {
	const url = `${appengineURL}/download-filters`;
	const token = await API.getFirebaseToken();

	const response = await API.post(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const fetchReportsSavedFilters = async (): Promise<SavedFiltersI[] | undefined> => {
	try {
		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		};

		const url = `${appengineURL}/download-filters`;
		const response = await fetch(url, options);

		if (response.status !== 200) throw new Error(`Error ${response.status}`);
		const responseData = await response.json();
		return responseData.data as SavedFiltersI[];
	} catch (error) {
		toast.error(error);
	}
};

export const deleteSavedFilter = async (filterID: string) => {
	const url = `${appengineURL}/download-filters/${filterID}`;
	const token = await API.getFirebaseToken();

	const response = await API.delete(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

/**
 * Attempt to authenticate user to the server.
 *
 * @param {string} email user email address
 * @param {string} password user password binded to email address
 * @param {props.firebase} firebase firebase object retrieved from props.firebase
 *
 * */
async function authenticateCredentials(
	email: string,
	password: string,
	firebase: any,
): Promise<{
	status: string;
	errorMsg: string;
	errorField: string;
	data: any;
}> {
	if (!email || !password) {
		return {
			status: 'field_not_complete',
			errorMsg: 'One or more fields are missing.',
			errorField: '',
			data: null,
		};
	} else {
		const creds = { email: email, password: password };

		return firebase
			.login(creds)
			.then(() => {
				return {
					status: 'ok',
					errorMsg: '',
					errorField: '',
				};
			})
			.catch((error: any) => {
				if (error.code === 'auth/user-not-found') {
					return {
						status: 'user_not_found',
						errorMsg:
							'Your account is not yet registered to our system. Please submit your access request to your account holder',
						errorField: 'email',
						data: null,
					};
				} else if (error.code === 'auth/invalid-email') {
					return {
						status: 'user_not_found',
						errorMsg: 'Email address is badly formatted. Please try again.',
						errorField: 'email',
						data: null,
					};
				} else if (error.code === 'auth/wrong-password') {
					return {
						status: 'wrong_password',
						errorMsg: 'Invalid credentials. Please try again',
						errorField: 'password',
						data: null,
					};
				} else if (error.code === 'auth/too-many-requests') {
					return {
						status: 'too_many_requests',
						errorMsg: 'Too many login errors. Please try again later.',
						errorField: '',
						data: null,
					};
				} else if (error.code === 'auth/user-disabled') {
					return {
						status: 'user_disabled',
						errorMsg: 'Your account has been temporarily disabled.',
						errorField: '',
						data: null,
					};
				} else {
					return {
						status: 'unknown_error',
						errorMsg: 'Unknown error',
						errorField: '',
						data: null,
					};
				}
			});
	}
}

export default authenticateCredentials;

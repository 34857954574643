/* eslint-disable no-undef */
import * as Sentry from '@sentry/react';
import TransitionModal from 'components/clientLogin/components/TransitionModal';
import { freshChatConfig } from 'config/freshchat';
import { ConnectedRouter, push } from 'connected-react-router';
import { createNewActivity } from 'constants/api/v1';
import { useFirebaseAuth } from 'hooks/useFirebaseAuth';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import * as Common from 'nimbly-common';
import React, { useMemo, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { isMobile } from 'react-device-detect';
import FreshChat from 'react-freshchat';
import ReactGA from 'react-ga';
import { InferableComponentEnhancerWithProps, connect, useSelector } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { fetchFeatureAccess } from 'reducers/featureAccess/featureAccess.action';
import { fetchOrganizations } from 'reducers/organizations/organizations.actions';
import { fetchTutorialAsync } from 'reducers/tutorial/tutorial.action';
import { setEnrollmentStatus } from 'reducers/userEnrollment/userEnrollment.action';
import { compose } from 'redux';
import { LMS_ROUTE } from 'routes/LmsRoutes';
import { RootState } from 'store/rootReducers';
import { FirebaseConnect } from 'types/app';
import { isActiveOrganization } from 'utils/organization';
import { version } from '../package.json';
import './App.css';
import FreezeAccountModal from './components/FreezeAccountModal/FreezeAccountModal';
import AsyncComponent from './components/global/AsyncComponent/AsyncComponent';
import LoadingPage from './pages/loading';
import LoginPage from './pages/login';
import NotFound from './pages/notfound';
import SetPasswordPage from './pages/setpassword';
import { setDummyState } from './reducers/account';
import { setGoogleMapsKey } from './reducers/gmaps';
import { setUserIsSandbox } from './reducers/user/user.action';
import { setUserAccessPermission } from './reducers/useraccess';
import { firebaseFunction, history } from './store/configStore';

const AdminRoute = AsyncComponent(() => import('./routes/admin-routes'));
const SuperadminRoute = AsyncComponent(() => import('./routes/superadmin-routes'));
interface GAAttribute {
	userId?: string;
	dimension1?: string; // org
	dimension2?: string; // version
	dimension3?: string; // uid
	dimension4?: string; // email
	dimension5?: string; // role
}

const App = (props: AppProps) => {
	const {
		profile,
		location,
		analytics,
		mapsApiKey,
		push,
		organization,
		setDummyState,
		setUserAccessPermission,
		fetchOrganization,
		setGoogleMapsKey,
		fetchFeatureAccess,
		verifyAuthCodeState,
		fetchTutorial,
		tutorialLoaded,
		userEnrollmentStatus,
		setEnrollmentStatus,
	} = props;

	const auth = useFirebaseAuth();
	const { user, isLoaded, signout } = auth;
	const [freezeModal, setFreezeModal] = useState<boolean>(false);
	const featureLms = useSelector((state: RootState) => state.featureAccess.features.LMS);
	const isFeatureLoaded = useSelector((state: RootState) => state.featureAccess.isLoaded);
	const loggedInUserOrgID = useSelector((state: RootState) => state?.firebase?.profile.organization);

	const handleDismissModal = () => {
		setFreezeModal(false);
	};

	const setupHotjarAttributes = (userAttributes: {
		id: string;
		email: string;
		phoneNumber: string;
		organization: string;
		role: string;
		versionID: string;
	}) => {
		// @ts-ignore
		window.__USER__ = userAttributes;
		// @ts-ignore
		if (window.hj) hj('identify', userAttributes.id, userAttributes);
	};
	const setupReactGAAttributes = (user: typeof auth['user'], profile: typeof props['profile'], version: string) => {
		let googleAnalyticsAttributes: GAAttribute = { dimension2: version };
		if (!isEmpty(user!.uid)) googleAnalyticsAttributes.userId = user!.uid;
		if (!isEmpty(profile.organization)) googleAnalyticsAttributes.dimension1 = profile.organization;
		if (!isEmpty(user!.uid)) googleAnalyticsAttributes.dimension3 = user!.uid;
		if (!isEmpty(user!.email)) googleAnalyticsAttributes.dimension4 = user!.email as string;
		if (!isEmpty(profile.role)) googleAnalyticsAttributes.dimension5 = profile.role;
		if (Object.keys(googleAnalyticsAttributes).length) {
			ReactGA.set(googleAnalyticsAttributes);
		}
	};
	React.useEffect(() => {
		if (user && userEnrollmentStatus) {
			fetchTutorial();
		}
	}, [user, userEnrollmentStatus]);

	// if url ends with / remove it
	React.useEffect(() => {
		const pathname = location.pathname;
		if (pathname !== '/' && /\/$/.test(pathname)) {
			push(pathname.slice(0, -1));
		}
	}, [location]);

	// Redirect user to login page if unauthenticated
	// Else fetch and set user role
	React.useEffect(() => {
		if (!isLoaded) {
			return;
		}

		if (!user) {
			if (!location.pathname.match(/(\/setpassword|\/login)/)) {
				push('/login');
			}

			return;
		}

		if (userEnrollmentStatus) {
			setUserAccessPermission();
			fetchOrganization();
			fetchFeatureAccess();
		}
	}, [
		isLoaded,
		user,
		location,
		userEnrollmentStatus,
		push,
		setUserAccessPermission,
		fetchOrganization,
		fetchFeatureAccess,
	]);

	React.useEffect(() => {
		if (user) {
			setDummyState(user.uid);
		}
	}, [user, setDummyState]);

	React.useEffect(() => {
		if (!isActiveOrganization(organization.organization?.clientStatus)) {
			setFreezeModal(true);
		}
	}, [organization]);

	// Location side effect on user authenticated
	// eslint-disable-next-line complexity
	React.useEffect(() => {
		if (!user || profile.isEmpty || profile.status === 'fresh' || !isFeatureLoaded) {
			return;
		}

		if (profile.status === 'disabled' || !profile.organization) {
			alert('ERROR: You are not authorized to login.');
			signout().finally(() => {
				localStorage.clear();
				push('/login');
			});
			return;
		}

		setupHotjarAttributes({
			id: user.uid,
			email: user.email || '',
			phoneNumber: user.phoneNumber || '',
			organization: profile.organization,
			role: profile.role || '',
			versionID: version,
		});

		setupReactGAAttributes(user, profile, version);

		const isFromLogin = location.pathname === '/login';
		const isFromRoot = location.pathname === '/';

		if (isFromLogin) {
			if (moment().unix() - moment(auth.user?.metadata.lastSignInTime).unix() < 15) {
				const lastLoginDate = auth.user?.metadata!.lastSignInTime || new Date();
				const activity = new Common.Activity({
					entity: Common.enums.Entity.USER,
					entityID: user.uid,
					permission: Common.enums.Permission.View,
					type: Common.enums.ActivityType.LOGIN_USER,
					message: `User Login: ${auth.user?.displayName}`,
					timestamp: new Date(),
					snapshot: {
						userData: {
							email: auth.user?.email,
							userID: auth.user?.uid,
							displayName: auth.user?.displayName,
						},
						lastSignInTime: new Date(lastLoginDate),
						platform: 'admin',
					},
				});
				try {
					createNewActivity(activity);
				} catch (err) {
					throw new Error(`Activity error, ${activity}`);
				}
			}
		}
		if (isFromLogin || isFromRoot) {
			if (organization.organization && isActiveOrganization(organization.organization?.clientStatus)) {
				const lmsType = organization?.organization?.lmsType;

				if (featureLms && (lmsType === 'standalone' || !props.adminAccess)) {
					if (isMobile && props.lmsRole === 'learner') {
						push(LMS_ROUTE.COURSE_AND_SYLLABUS_CATALOG);
						return;
					}

					push(LMS_ROUTE.DASHBOARD);
					return;
				}

				// if (loggedInUserOrgID === 'nimbly') {
				// 	push('/analytics/overview');
				// } else {
				// 	push('/admin/sites');
				// }
				push('/analytics/executive');
				return;
			}
		}

		/**
		 * Fallback if some trying to access `/analytics/auditorDetails` directly
		 */
		const isAccessingAuditorDetail = location.pathname === '/analytics/auditorDetails/';
		const isQueryAllUser = analytics.selectedAuditor === '_all_';
		if (isAccessingAuditorDetail && isQueryAllUser) {
			if (isActiveOrganization(organization.organization?.clientStatus)) {
				push('/analytics/auditors');
			}
		}
	}, [user, location, analytics, profile, push, signout, organization, props.adminAccess, isFeatureLoaded, featureLms]);

	React.useEffect(() => {
		if (!user || profile.isEmpty || mapsApiKey) {
			return;
		}
		const retrieveGoogleMapsKey = firebaseFunction().httpsCallable('retrieveGoogleMapsKey');
		const localKey = localStorage.getItem('gmaps:main');
		const localKeyDate = localStorage.getItem('gmaps:date');

		if (localKey && localKeyDate && moment(localKeyDate).diff(moment(), 'days') < 31) {
			setGoogleMapsKey(localKey);

			return;
		}
		retrieveGoogleMapsKey()
			.then((res) => {
				setGoogleMapsKey(res.data);
				localStorage.setItem('gmaps:main', res.data);
				localStorage.setItem('gmaps:date', moment().toISOString());
			})
			.catch((error) => {
				console.error(error);
			});
	}, [user, profile, mapsApiKey, setGoogleMapsKey]);

	const authLoaded = user && isLoaded;

	const shouldShowTransitionModal = useMemo(() => {
		return (
			!verifyAuthCodeState.loading &&
			!!verifyAuthCodeState.tokenInfo.clientToken &&
			!!verifyAuthCodeState.tokenInfo.internalToken &&
			!verifyAuthCodeState.error
		);
	}, [verifyAuthCodeState.loading, verifyAuthCodeState.tokenInfo, verifyAuthCodeState.error]);

	const checkIsSandbox = async () => {
		if (!props?.firebase.auth()?.currentUser) return;
		const res = await props?.firebase.auth()?.currentUser?.getIdTokenResult();
		props.dispatch(setUserIsSandbox(!!res?.claims?.isSandboxAccount));
	};

	React.useEffect(() => {
		setEnrollmentStatus();
	}, []);

	React.useEffect(() => {
		checkIsSandbox();
	}, [props?.firebase.auth()?.currentUser]);

	return (
		<ConnectedRouter history={history}>
			<TransitionModal isVisible={shouldShowTransitionModal} orgName={verifyAuthCodeState.tokenInfo.organizationName} />
			{/* @deprecated Please use [containerId=toast-message-container] to apply new design to new toasts */}
			<ToastContainer
				enableMultiContainer
				hideProgressBar
				autoClose={5000}
				toastClassName="toast"
				bodyClassName="toast-body"
			/>
			<ToastContainer
				enableMultiContainer
				containerId="toast-message-container"
				hideProgressBar
				autoClose={5000}
				toastClassName="toast-message"
				bodyClassName="toast-message-body"
			/>

			<FreezeAccountModal isVisible={freezeModal} handleDismissModal={handleDismissModal} {...props} />
			{authLoaded &&
			profile.isLoaded &&
			location.pathname !== '/setpassword' &&
			profile.status !== 'fresh' &&
			isActiveOrganization(organization.organization?.clientStatus) &&
			tutorialLoaded ? (
				<Switch>
					<Route path="/loading" component={LoadingPage} />
					<Route path="/login" component={LoginPage} />
					<Route path="/setpassword" component={SetPasswordPage} />

					{profile.role === 'superadmin' ? <SuperadminRoute /> : <AdminRoute />}

					<Route component={NotFound} />
				</Switch>
			) : (
				<Switch>
					<Route exact path="/" component={LoadingPage} />
					<Route path="/login" component={LoginPage} />
					<Route path="/setpassword" component={SetPasswordPage} />
					<Route component={LoadingPage} />
				</Switch>
			)}
			{authLoaded && profile && profile.email ? (
				<FreshChat
					token={freshChatConfig.key}
					onInit={(widget: any) => {
						if (profile?.email) {
							widget.user.setProperties({
								email: profile.email,
								first_name: profile.displayName,
								phone: profile.phoneNumber,
							});
						}
					}}
				/>
			) : null}
		</ConnectedRouter>
	);
};

const mapStateToProps = (state: RootState) => ({
	auth: state.firebase.auth,
	profile: state.firebase.profile,
	location: state.router.location,
	mapsApiKey: state.gmaps.apiKey,
	analytics: state.analytics,
	organization: state.organization,
	verifyAuthCodeState: state.clientLogin.verifyAuthCode,
	tutorialLoaded: state.tutorial.isLoaded,
	userEnrollmentStatus: state.userEnrollment.enrollmentStatus,
	adminAccess: state.userAccess.admin.sites.all.permissions.edit,
	lmsRole: state.userAccess.lmsRole,
});

const mapDispatchToProps = {
	setGoogleMapsKey,
	setDummyState,
	push,
	setUserAccessPermission,
	fetchOrganization: fetchOrganizations.request,
	fetchFeatureAccess: fetchFeatureAccess.request,
	fetchTutorial: fetchTutorialAsync.request,
	setEnrollmentStatus,
};
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type EnhancedProps = StateProps & DispatchProps & FirebaseConnect & { dispatch: any };

type AppProps = OwnProps & EnhancedProps;

const enhance: InferableComponentEnhancerWithProps<EnhancedProps, OwnProps> = compose(
	connect(mapStateToProps, mapDispatchToProps),
	firebaseConnect(),
);

export default enhance(Sentry.withProfiler(App));

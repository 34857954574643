import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './adminDashboard.action';
import { AdminDashboardStore } from './adminDashboardStore';
import moment from 'moment';

export type AdminDashboardAction = ActionType<typeof actions>;

export const initialState: AdminDashboardStore = {
	filters: {
		startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
	},
	organizationId: null,
	totalUsersInfo: { activeUsers: 0, disabledUsers: 0, freshUsers: 0, totalUsers: 0 },
	totalSitesInfo: { activeSites: 0, inactiveSites: 0, totalSites: 0 },
	totalDevicesInfo: { totalDevices: 0, totalAndroid: 0, totalIos: 0, totalOthers: 0 },
	avgLoginInfo: { avgLoginsPerDay: 0 },
	usersCvUInfo: [],
	sitesCvUInfo: [],
	avgVTotalReportsInfo: [],
	mauInfo: { totalUsers: 0, MAU: [] },
	storageInfo: { totalAllowedStorageGB: 50, totalUsedStorageGB: 0 },
	showErrorModal: false,
	selectedOrg: null,
};

export const adminDashboardReducer = createReducer<AdminDashboardStore, AdminDashboardAction>(initialState)
	.handleAction(actions.setFilters, (state, action) => ({
		...state,
		filters: action.payload.filters,
	}))
	.handleAction(actions.setOrgId, (state, action) => ({
		...state,
		organizationId: action.payload.orgId,
	}))
	.handleAction(actions.setShowErrorModal, (state, action) => ({
		...state,
		showErrorModal: action.payload.value,
	}))
	.handleAction(actions.setSelectedOrg, (state, action) => ({
		...state,
		selectedOrg: action.payload.value,
	}))
	.handleAction(actions.fetchTotalActiveUsersAsync.success, (state, action) => {
		return {
			...state,
			totalUsersInfo: action.payload,
		};
	})
	.handleAction(actions.fetchTotalActiveSitesAsync.success, (state, action) => {
		return {
			...state,
			totalSitesInfo: action.payload,
		};
	})
	.handleAction(actions.fetchTotalLoginDevicesAsync.success, (state, action) => {
		return {
			...state,
			totalDevicesInfo: action.payload,
		};
	})
	.handleAction(actions.fetchAvgLoginInfoAsync.success, (state, action) => {
		return {
			...state,
			avgLoginInfo: action.payload,
		};
	})
	.handleAction(actions.fetchUsersCvUAsync.success, (state, action) => {
		return {
			...state,
			usersCvUInfo: action.payload,
		};
	})
	.handleAction(actions.fetchSitesCvUAsync.success, (state, action) => {
		return {
			...state,
			sitesCvUInfo: action.payload,
		};
	})
	.handleAction(actions.fetchAvgVTotalReportsAsync.success, (state, action) => {
		return {
			...state,
			avgVTotalReportsInfo: action.payload,
		};
	})
	.handleAction(actions.fetchMAUAsync.success, (state, action) => {
		return {
			...state,
			mauInfo: action.payload,
		};
	})
	.handleAction(actions.fetchStorageAsync.success, (state, action) => {
		return {
			...state,
			storageInfo: action.payload,
		};
	});
